import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIHelperService } from '../api-helper.service';

@Injectable({
  providedIn: 'root'
})
export class OptionProvidersService {

  httpHeaders: any;
  constructor(
    private apiHelperService: APIHelperService
  ) { 
    this.httpHeaders = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
      })
    };
  }

  loadGrades(formTeacherAllowedGrades = 0) {
    return new Observable((observer) => {

      this.apiHelperService.get(`${environment.apiURI}/teacher/option-provider/grades/${formTeacherAllowedGrades}`,
      this.httpHeaders)
        .subscribe(
          response => {
            observer.next(response);
            observer.complete();
          }      
        );
      });
  }
  
  loadSections(gradeId, formTeacherAllowedGrades=0) {
    return new Observable((observer) => {

      this.apiHelperService.get(`${environment.apiURI}/teacher/option-provider/sections/${gradeId}/${formTeacherAllowedGrades}`,
      this.httpHeaders)
        .subscribe(
          response => {
            observer.next(response);
            observer.complete();
          }      
        );
      });
  }
  
  loadSubjects(gradeId) {
    return new Observable((observer) => {

      this.apiHelperService.get(`${environment.apiURI}/teacher/option-provider/subjects/${gradeId}`,
      this.httpHeaders)
        .subscribe(
          response => {
            observer.next(response);
            observer.complete();
          }      
        );
      });
  }
}
