import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIHelperService } from './api-helper.service';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {

  httpHeaders: any;
  constructor(private apiHelperService: APIHelperService) { 
    this.httpHeaders = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
      })
    };
  }

  public getStudentAttendance(month): Observable<any> {
    return new Observable((observer) => {
      this.apiHelperService.post(`${environment.apiURI}/student/attendance`, 
      {
        'month': month
      }, 
      this.httpHeaders)
        .subscribe(
          authResponse => {
            observer.next(authResponse);
            observer.complete();
          }      
        );
      });
  }

  loadStudentAttendance(filter) {
    return new Observable((observer) => {
      this.apiHelperService.post(`${environment.apiURI}/teacher/student-attendances`,
      filter,
      this.httpHeaders)
        .subscribe(
          response => {
            observer.next(response);
            observer.complete();
          }      
        );
      });
  }

  saveAttendance(studentAttendance) {
    return new Observable((observer) => {
      this.apiHelperService.post(`${environment.apiURI}/teacher/student-attendances/${studentAttendance.date}`,
      studentAttendance,
      this.httpHeaders)
        .subscribe(
          response => {
            observer.next(response);
            observer.complete();
          }      
        );
      });
  }

  loadStaffAttendanceLog() {
    return new Observable((observer) => {
      this.apiHelperService.get(`${environment.apiURI}/teacher/staff/attendance-log`,
      this.httpHeaders)
        .subscribe(
          response => {
            observer.next(response);
            observer.complete();
          }      
        );
      });
  }
}
