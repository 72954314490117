import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { APIHelperService } from '../api-helper.service';

@Injectable({
  providedIn: 'root'
})
export class ResultService {

  httpHeaders: any;

  constructor(private apiHelperService: APIHelperService) { 
    this.httpHeaders = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
      })
    };
  }

  loadStudentSessions() {
    return new Observable((observer) => {
      this.apiHelperService.get(`${environment.apiURI}/student/option-provider/student-sessions`,
      this.httpHeaders)
        .subscribe(
          response => {
            observer.next(response);
            observer.complete();
          }      
        );
      });
  }
  loadSessionExams(sessionId = null) {
    return new Observable((observer) => {
      let url = null;
      if(null != sessionId) {

        url = `${environment.apiURI}/student/option-provider/exams/${sessionId}`;
      } else {
        url = `${environment.apiURI}/teacher/option-provider/exams`;
      }

      this.apiHelperService.get(url,
      this.httpHeaders)
        .subscribe(
          response => {
            observer.next(response);
            observer.complete();
          }      
        );
      });
  }
  loadGradeBook(examId) {
    return new Observable((observer) => {
      this.apiHelperService.post(`${environment.apiURI}/student/gradebook`,
      {
        'exam_ids':[examId]
      },
      this.httpHeaders)
        .subscribe(
          response => {
            observer.next(response);
            observer.complete();
          }      
        );
      });
  }
  loadStudentGradebook(filter) {
    return new Observable((observer) => {
      this.apiHelperService.post(`${environment.apiURI}/teacher/students-gradebook`,
      filter,
      this.httpHeaders)
        .subscribe(
          response => {
            observer.next(response);
            observer.complete();
          }      
        );
      });
  }
  saveGradebook(gradebook) {
    return new Observable((observer) => {
      this.apiHelperService.post(`${environment.apiURI}/teacher/save-students-gradebook`,
      gradebook,
      this.httpHeaders)
        .subscribe(
          response => {
            observer.next(response);
            observer.complete();
          }      
        );
      });
  }
}
